import React, { CSSProperties, useCallback, useEffect, useState } from 'react';
import {
  Grid as TableGrid,
  TableSelection,
} from '@devexpress/dx-react-grid-material-ui';
import {
  ChecklistInProgressProvider,
  DocumentActionsProvided,
  SituationProvider,
  TextProvider,
} from './DocumentProviders';
import Information from 'components/modal/Information';
import DocumentAttachments from './DocumentAttachments';
import {
  SymphonyTable,
  SymphonyTableHeaderRow,
} from 'components/gridFormatters';
import { useGetEpisodeDocumentsQuery } from 'graphql/hooks/getEpisodeDocuments';
import {
  Attachment,
  Checklist,
  ChecklistStatus,
  ChecklistViewType,
  EpisodesTabChecklist,
} from 'graphql/graphqlTypes';
import Loader from 'components/loader';
import EpisodesDateTimeProvider from 'components/home/grid/providers/EpisodesDateTimeProvider';
import { EpisodeTypes } from '../consts';
import { useDispatch, useSelector } from 'react-redux';
import { setChecklistIds } from 'store/ui/modals/checklist';
import {
  IntegratedSelection,
  Row,
  SelectionState,
} from '@devexpress/dx-react-grid';
import { ICONS } from 'components/icon';
import { ActionButton } from 'features/letters/list';
import styled from 'styled-components';
import { IState } from 'store';
import DocumentsPrintPreview from './DocumentsPrintPreview';
import {
  updateMozartDocumentState,
  updateNonMozartDocumentState,
} from 'store/ui/print/documents';

export interface IDocumentsTabProps {
  style?: CSSProperties;
  episodeId: number;
  editingChecklistId?: string;
  readonly?: boolean;
  episodeType: EpisodeTypes;
  workflowInstanceId?: string;
}
const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 12px;
`;

const Documents = (props: IDocumentsTabProps) => {
  const {
    episodeId,
    editingChecklistId,
    style,
    episodeType,
    workflowInstanceId,
  } = props;
  const dispatch = useDispatch();

  // need both to disable blinking
  const [showAttachmentsForChecklistId, setShowAttachmentsForChecklistId] =
    React.useState(0);
  const [showAttachments, setShowAttachments] = React.useState(false);
  const [isPrintOpen, setIsPrintOpen] = useState(false);
  const [selection, setSelection] = useState<number[]>([]);
  const reduxMozartInstanceId = useSelector(
    (state: IState) => state.ui.print.documents.mozart.mozartInstanceId
  );
  const isMozart =
    !!workflowInstanceId &&
    workflowInstanceId !== '00000000000000000000000000000000' &&
    workflowInstanceId !== 'NaN';
  const columns = [
    {
      name: 'started',
      title: 'Created',
      getCellValue: (row: EpisodesTabChecklist) => row?.checklist?.createdOn,
    },
    {
      name: 'finished',
      title: 'Finished',
      getCellValue: (row: EpisodesTabChecklist) => row?.checklist?.finishedOn,
    },
    {
      name: 'situation',
      title: 'Situation',
      getCellValue: (row: EpisodesTabChecklist) => ({
        checklistId: row?.checklist?.id ?? 0,
        setShowAttachmentsForChecklistId,
        setShowAttachments,
      }),
    },
    {
      name: 'author',
      title: 'Created By',
      getCellValue: (row: EpisodesTabChecklist) =>
        row?.checklist?.createdByUser?.fullName,
    },
    { name: 'progress', title: 'Progress' },
    {
      name: 'operations',
      title: 'Actions',
      getCellValue: () => ({
        refetch,
      }),
    },
  ];

  const columnsExtension = [
    { columnName: 'started', sortingEnabled: false, width: '15%' },
    { columnName: 'finished', sortingEnabled: false, width: '15%' },
    {
      columnName: 'situation',
      sortingEnabled: false,
      width: '25%',
    },
    { columnName: 'author', sortingEnabled: false, width: '15%' },
    { columnName: 'progress', sortingEnabled: false, width: '10%' },
    { columnName: 'operations', sortingEnabled: false, width: '20%' },
  ];

  const { data, isFetching, refetch } = useGetEpisodeDocumentsQuery(
    {
      episodeId: episodeId,
      viewType:
        episodeType === 'cases'
          ? ChecklistViewType.HistoryTab
          : ChecklistViewType.EpisodeTab,
    },
    { skip: episodeId <= 0 }
  );

  let rows = data?.getEpisodeDocuments ?? [];
  if (editingChecklistId) {
    const checklistId = Number(editingChecklistId);
    rows = rows.map((s) => {
      if (s?.checklist?.id === checklistId && s.operations) {
        return { ...s, operations: s.operations.filter((m) => m !== 'DELETE') };
      }

      return s;
    });
  }

  const checklistIds = rows.map((row) => row?.checklist?.id).filter(Boolean);
  const checklists = rows
    .map((row) => row?.checklist)
    .filter(Boolean) as Checklist[];
  const areAllChecklistsCompleted = (checkLists: Checklist[]): boolean => {
    return (
      checkLists?.every(
        (checklist) => checklist.status === ChecklistStatus.Completed
      ) ?? false
    );
  };
  const isAllChecklistsCompleted = areAllChecklistsCompleted(checklists);
  useEffect(() => {
    if (checklistIds.length > 0) {
      dispatch(setChecklistIds(checklistIds));
    }
  }, [checklistIds, dispatch]);

  useEffect(() => {
    if (reduxMozartInstanceId !== workflowInstanceId) {
      setSelection([]);
    }
  }, [reduxMozartInstanceId, workflowInstanceId]);

  const handlePrint = () => {
    setIsPrintOpen(true);
    const selectedRowIds = selection;
    if (isMozart) {
      dispatch(
        updateMozartDocumentState({
          episodeId: episodeId ?? 0,
          checklistIds: selectedRowIds ?? [],
          mozartInstanceId: workflowInstanceId,
        })
      );
    } else {
      dispatch(
        updateNonMozartDocumentState({
          episodeId: episodeId ?? 0,
          selectedChecklistIds: selectedRowIds ?? [],
        })
      );
    }
  };

  const handleClose = useCallback(() => {
    setShowAttachments(false);
  }, [setShowAttachments]);
  const getRowId = (row: Row) => row.checklist?.id ?? 0;

  return (
    <>
      <StyledButtonWrapper>
        <div>
          <ActionButton
            icon={ICONS.Preview}
            text="Print Preview"
            active={isAllChecklistsCompleted && selection.length > 0}
            onClick={() => handlePrint()}
            buttonTestId="print-preview-button"
          />
        </div>
      </StyledButtonWrapper>
      {isPrintOpen && (
        <DocumentsPrintPreview
          open={isPrintOpen}
          onClose={() => setIsPrintOpen(false)}
        />
      )}
      <div style={style} data-testid="document-tab">
        <div data-testid="document-grid">
          <TableGrid columns={columns} rows={rows} getRowId={getRowId}>
            <SelectionState
              selection={selection}
              onSelectionChange={(newSelection) =>
                setSelection(newSelection as number[])
              }
            />
            <IntegratedSelection />
            <EpisodesDateTimeProvider for={['started', 'finished']} />
            <SituationProvider for={['situation']} />
            <TextProvider for={['author']} />
            <ChecklistInProgressProvider for={['progress']} />
            <DocumentActionsProvided for={['operations']} />
            <SymphonyTable columnExtensions={columnsExtension} />
            <SymphonyTableHeaderRow showSortingControls={false} />
            <TableSelection selectByRowClick showSelectAll />
          </TableGrid>
        </div>
        <Information
          open={showAttachments}
          okEvent={handleClose}
          title="Attachments"
        >
          <DocumentAttachments
            attachments={
              (data?.getEpisodeDocuments && data?.getEpisodeDocuments.length > 0
                ? (data?.getEpisodeDocuments ?? []).find(
                    (item) =>
                      item?.checklist?.id === showAttachmentsForChecklistId
                  )?.checklist?.attachments
                : []) as Attachment[]
            }
          />
        </Information>
        <Loader active={isFetching} />
      </div>
    </>
  );
};

export default Documents;
