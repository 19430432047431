import React, { useEffect, useState } from 'react';
import { Box, Grid, Stack, Typography, styled } from '@mui/material';
import { Select } from 'components/select';
import theme from 'theme';
import {
  dashBoardAuthorization,
  dashBoardGridPriority,
} from './DashBoardGridHelper';
import { useUpdateUserPreferencesMutation } from 'graphql/hooks/updateUserPreferences';
import { IUserPreferences } from 'backend/types/userPreferences';

export interface DashboardGridHeaderProps {
  headerName: string;
  priority: string;
  setDefaultAuthorization: (value: string) => void;
  handleFetchChange: (_priority: string, auth: string) => void;
  setUserPreferences: (value: IUserPreferences) => void;
  userPreferences: IUserPreferences;
}

const StyledGrid = styled(Grid)({
  gap: theme.spacing(1),
  spacing: theme.spacing(1),
  alignItems: 'center',
  height: '45px',
  justifyContent: 'space-between',
  rowSpacing: 0,
});

const SelectionTypography = styled(Typography)`
  margin-left: 0px;
  margin-top: 6px;
  font-weight: bold;
  font-size: 14px;
`;

const DashboardGridHeader = ({
  headerName,
  handleFetchChange,
  priority,
  userPreferences,
  setUserPreferences,
}: DashboardGridHeaderProps) => {
  const [standardValue, setStandardValue] = useState(
    userPreferences?.standardAuthorization
  );
  const [expeditedValue, setExpeditedValue] = useState(
    userPreferences?.expeditedAuthorization
  );
  const [updateUserPreferences] = useUpdateUserPreferencesMutation();
  const handleSelectChange = (
    event: React.ChangeEvent<{ name: string | undefined; value: string }>
  ) => {
    const currentValue = event.target.value;
    priority == dashBoardGridPriority.expedited &&
      setExpeditedValue(currentValue);
    priority == dashBoardGridPriority.standard &&
      setStandardValue(currentValue);
    const currentPreference = {
      firstName: userPreferences?.firstName,
      lastName: userPreferences?.lastName,
      prefix: userPreferences?.prefix,
      suffix: userPreferences?.suffix,
      credentials: userPreferences?.credentials,
      phone: userPreferences?.phone,
      email: userPreferences?.email,
      defaultDashboard: userPreferences?.defaultDashboard,
      standardAuthorization:
        priority == dashBoardGridPriority.standard
          ? currentValue
          : userPreferences?.standardAuthorization,
      expeditedAuthorization:
        priority == dashBoardGridPriority.expedited
          ? currentValue
          : userPreferences?.expeditedAuthorization,
      cmRnCaseLoadDashboardFilter: userPreferences?.cmRnCaseLoadDashboardFilter,
    };
    updateUserPreferences({
      data: currentPreference,
    });
    setUserPreferences(currentPreference as IUserPreferences);
    const auth =
      priority == dashBoardGridPriority.expedited
        ? currentPreference?.expeditedAuthorization
        : currentPreference?.standardAuthorization;
    handleFetchChange(priority, auth ?? '');
  };

  useEffect(() => {
    if (userPreferences?.standardAuthorization) {
      setStandardValue(userPreferences?.standardAuthorization ?? '');
    }
    if (userPreferences?.expeditedAuthorization) {
      setExpeditedValue(userPreferences?.expeditedAuthorization ?? '');
    }
  }, [
    userPreferences.expeditedAuthorization,
    userPreferences.standardAuthorization,
  ]);

  return (
    <StyledGrid item xs={14} container data-testid="dashboard-grid-header">
      <Grid>
        <Stack spacing={3} direction="row">
          <Grid item>
            <SelectionTypography>{headerName}</SelectionTypography>
          </Grid>
        </Stack>
      </Grid>
      <Grid>
        <Box display="flex">
          <Select
            data-testid="dashboard-filter-select"
            value={
              priority == dashBoardGridPriority.standard
                ? standardValue
                : expeditedValue
            }
            onChange={handleSelectChange}
            items={[
              {
                id: dashBoardAuthorization.all,
                name: dashBoardAuthorization.all,
              },
              {
                id: dashBoardAuthorization.myOwned,
                name: dashBoardAuthorization.myOwned,
              },
              {
                id: dashBoardAuthorization.unassigned,
                name: dashBoardAuthorization.unassigned,
              },
            ]}
            size="small"
            fullWidth
            required
            id="dashboard-filter-select"
          />
        </Box>
      </Grid>
    </StyledGrid>
  );
};

export default DashboardGridHeader;
