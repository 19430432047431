import React from 'react';
import Typography from '@mui/material/Typography';
import SectionBody from './SectionBody';
import SectionNumber from 'components/actions/sectionNumber';
import { COLORS } from 'consts/styles';
import { GetHtmlId } from 'components/actions/navbar';
import CarePlan from 'components/actions/sections/SectionBody/CarePlan/carePlan';
import { ChecklistStorageType } from 'store/actions/types';
import { useSelector } from 'react-redux';
import { IState } from 'store';
import {
  ChecklistCategory,
  ChecklistItem,
  ChecklistViewModel,
} from 'graphql/graphqlTypes';
import styled from 'styled-components';
import { IEntityLookup, ILookup } from 'backend/types/lookup';
interface StyledProps {
  isPrinting: boolean;
}

const StyledCategoryHeader = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  position: relative;
  padding: ${({ isPrinting }) =>
    isPrinting ? '0 5px 5px 5px' : '0 28px 16px 28px'};
  ${({ isPrinting }) =>
    isPrinting &&
    `page-break-inside: avoid;    
  `}
`;

const StyledMainWrapper = styled.div<StyledProps>`
  padding-bottom: ${({ isPrinting }) => (isPrinting ? '5px' : '20px')};
  margin-bottom: ${({ isPrinting }) => (isPrinting ? '5px' : '24px')};
  background-color: ${COLORS.WHITE};
`;
const StyledSubCategory = styled.div<StyledProps>`
  padding-left: ${({ isPrinting }) => (isPrinting ? '5px' : '32px')};
`;
const StyledPanel = styled.div<StyledProps>`
  padding-top: ${({ isPrinting }) => (isPrinting ? '5px' : '20px')};
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledAnchor = styled.div`
  position: absolute;
  top: -120px;
  left: 0;
`;
const StyledTitle = styled(Typography)<StyledProps>`
  font-size: ${({ isPrinting }) => (isPrinting ? '15px' : '20px')};
  font-weight: 500;
  padding-left: ${({ isPrinting }) => (isPrinting ? '5px' : '8px')};
  letter-spacing: ${({ isPrinting }) => (isPrinting ? 0.05 : 0.15)}px;
  line-height: ${({ isPrinting }) => (isPrinting ? '5px' : '24px')};
`;

const isVisibleItem = (item: ChecklistItem) => {
  const isDisplayOnlyWhenSelected =
    'lines' in item &&
    item.isSelected &&
    item.lines
      .flatMap((el) => el.components.some((i) => i.displayOnlyWhenSelected))
      .some(Boolean);
  return (item.isVisible && item.isSelected) || isDisplayOnlyWhenSelected;
};

const selectedVisibleItems = (category: ChecklistCategory) =>
  category.items.filter(isVisibleItem).length > 0;

const selectVisibleCategories = (category: ChecklistCategory) =>
  selectedVisibleItems(category) ||
  category.subCategories.filter(selectedVisibleItems).length > 0;

const checklistSubcategories = (subCategories: ChecklistCategory[]) =>
  subCategories.filter(selectedVisibleItems);

export interface ISectionsProps {
  checklist?: ChecklistViewModel | null;
  storageType: ChecklistStorageType;
  hideNumbers?: boolean;
  actionInstanceId?: string | null;
}

const ReviewSections = (props: ISectionsProps) => {
  const { storageType, hideNumbers, actionInstanceId } = props;
  const isMozartFlow =
    storageType === ChecklistStorageType.MOZART ||
    storageType === ChecklistStorageType.VIEW;

  const checklist = useSelector((state: IState) =>
    props.checklist
      ? props.checklist
      : state.checklist.documentsState[storageType]?.checklist
  );
  const problems = useSelector(
    (state: IState) =>
      state.checklist.documentsState[ChecklistStorageType.CAREPLAN]?.problems
  );
  const printDocuments = useSelector(
    (state: IState) => state.ui.print.documents
  );
  const checklistCategories = checklist?.categories.filter(
    selectVisibleCategories
  );
  const carePlanIndex = checklistCategories?.length ?? 0;
  const isPrintOpen = printDocuments.isPrintOpen;
  const getCategoryItems = (
    items: ChecklistItem[],
    categoryId: string,
    mozartFlow: boolean
  ) => {
    return items
      .filter(isVisibleItem)
      .map((item: ChecklistItem, itemIndex: number) => (
        <StyledWrapper
          key={item.uid}
          style={{
            padding: `${mozartFlow ? '0 24px 0 24px' : '0 -10px 0 -10px'}`,
            backgroundColor: `${
              !(itemIndex & 1) ? COLORS.GREY4 : COLORS.WHITE
            }`,
          }}
        >
          <SectionBody
            checklistItem={item}
            checklistId={checklist?.id}
            bigTableValues={
              (checklist?.bigTableValues as IEntityLookup[]) ?? []
            }
            entityLookups={(checklist?.entities as IEntityLookup[]) ?? []}
            attributeTypes={checklist?.attributeTypes ?? []}
            lookups={(checklist?.lookups as ILookup[]) ?? []}
            categoryId={categoryId}
            storageType={storageType ?? ChecklistStorageType.CHECKLIST}
          />
        </StyledWrapper>
      ));
  };

  return (
    <StyledMainWrapper
      isPrinting={isPrintOpen}
      style={{
        border: `${isMozartFlow ? '1px solid #d7d7d7' : '0'}`,
        borderRadius: `${isMozartFlow ? '4px' : '0'}`,
        position: 'relative',
      }}
    >
      <StyledAnchor id={`section-${checklist?.id}`} />
      {isMozartFlow && actionInstanceId && (
        <StyledAnchor id={`section-${actionInstanceId}`} />
      )}
      {checklistCategories?.map(
        (category: ChecklistCategory, categoryIndex: number) => (
          <StyledPanel
            isPrinting={isPrintOpen}
            key={category.id}
            style={{
              padding: `${isMozartFlow ? '20px 0 0 0' : '20px 28px 0 28px'}`,
            }}
          >
            <StyledCategoryHeader isPrinting={isPrintOpen}>
              <StyledAnchor id={GetHtmlId(category.name, category.id)} />
              {hideNumbers ? null : (
                <SectionNumber
                  number={categoryIndex + 1}
                  categoryId={category.id}
                  isReadOnly={category.isReadOnly}
                  category={category}
                  size="large"
                  storageType={storageType}
                  status="done"
                />
              )}
              <StyledTitle
                isPrinting={isPrintOpen}
                variant="h4"
                style={{ paddingLeft: '8px' }}
              >
                {category.name}
              </StyledTitle>
            </StyledCategoryHeader>
            {getCategoryItems(category.items, category.id, isMozartFlow)}

            <StyledSubCategory isPrinting={isPrintOpen}>
              {checklistSubcategories(category.subCategories).map(
                (subCategory: ChecklistCategory) => (
                  <StyledWrapper key={subCategory.id}>
                    {subCategory.items.map((item: ChecklistItem) => (
                      <SectionBody
                        checklistItem={item}
                        key={item.uid}
                        bigTableValues={
                          (checklist?.bigTableValues as IEntityLookup[]) ?? []
                        }
                        entityLookups={
                          (checklist?.entities as IEntityLookup[]) ?? []
                        }
                        attributeTypes={checklist?.attributeTypes ?? []}
                        lookups={(checklist?.lookups as ILookup[]) ?? []}
                        categoryId={category.id}
                        storageType={
                          storageType ?? ChecklistStorageType.CHECKLIST
                        }
                      />
                    ))}
                  </StyledWrapper>
                )
              )}
            </StyledSubCategory>
          </StyledPanel>
        )
      )}
      {checklist?.includeCarePlan ? (
        <StyledPanel
          isPrinting={isPrintOpen}
          style={{
            padding: `${isMozartFlow ? '20px 0 0 0' : '20px 28px 0 28px'}`,
          }}
        >
          <StyledCategoryHeader id="careplan" isPrinting={isPrintOpen}>
            <SectionNumber
              number={carePlanIndex + 1}
              categoryId={carePlanIndex.toString()}
              isReadOnly={false}
              category={{} as ChecklistCategory}
              storageType={ChecklistStorageType.CAREPLAN}
            />
            <Typography variant="h4" style={{ marginLeft: '5px' }}>
              Problem List
            </Typography>
          </StyledCategoryHeader>
          <StyledCategoryHeader isPrinting={isPrintOpen}>
            <div style={{ paddingLeft: '32px' }}>
              <CarePlan isReadOnly={true} carePlanProblems={problems ?? []} />
            </div>
          </StyledCategoryHeader>
        </StyledPanel>
      ) : null}
    </StyledMainWrapper>
  );
};

export default ReviewSections;
