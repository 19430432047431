import React, { useEffect, useState } from 'react';
import { Box, Button, FormControl, Grid, Typography } from '@mui/material';
import { Select } from 'components/select';
import Icon, { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';
import CarePlanProblemsGrid from './carePlanProblemsGrid';
import { problemsColumnNames } from 'components/actions/sections/SectionBody/CarePlan/carePlanProblems';
import ProblemsDialog from './problemsDialog';
import { IState } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { ChecklistStorageType } from 'store/actions/types';
import { StyledDivider } from './styles';
import Sections from '../../sections';
import ReviewSections from 'components/review/sections';
import { updateCarePlanModelSuccess } from 'store/actions/checklistSlice';
import Loader from 'components/loader';
import { usePrevious } from 'hooks';
import { hashNumbers } from 'util/helpers/arrayFunctions';
import { PATIENT_CARE_PLAN_FILTER_STATE } from 'components/constants';
import Confirmation from 'components/modal/Confirmation';
import { useAddChecklistProblemsMutation } from 'graphql/hooks/addChecklistProblems';
import { useUpdateChecklistProblemMutation } from 'graphql/hooks/updateChecklistProblem';
import { useDeleteChecklistProblemMutation } from 'graphql/hooks/deleteChecklistProblem';
import { useLazyRefreshCarePlanQuery } from 'graphql/hooks/refreshCarePlan';
import { useCreateOrUpdateCarePlanMutation } from 'graphql/hooks/createOrUpdateCarePlan';
import { useSetPreferenceMutation } from 'graphql/hooks/setPreference';
import {
  ChecklistProblemViewModel,
  ChecklistViewModel,
  CreateOrUpdateCarePlanActionContextInput,
  PatientCarePlanStatusFilterViewModel,
  Problem,
} from 'graphql/graphqlTypes';
export interface ICarePlanProps {
  isReadOnly?: boolean;
  carePlanProblems?: ChecklistProblemViewModel[];
  updateCarePlanProblems?: (problems: ChecklistProblemViewModel[]) => void;
}

enum ButtonState {
  Disabled = 'Disabled',
  Create = 'Create',
  Update = 'Update',
  Delete = 'Delete',
}

const CarePlan = (props: ICarePlanProps) => {
  const { isReadOnly, carePlanProblems, updateCarePlanProblems } = props;
  const dispatch = useDispatch();
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const problems = useSelector(
    (state: IState) =>
      state.checklist.documentsState[ChecklistStorageType.CAREPLAN]?.problems
  );

  const hasCarePlan = useSelector(
    (state: IState) =>
      state.checklist.documentsState[ChecklistStorageType.CAREPLAN]
        ?.checklist != null
  );

  const carePlanFilterState = useSelector(
    (state: IState) =>
      state.checklist.documentsState[ChecklistStorageType.CAREPLAN]?.checklist
        ?.filterState
  );

  const [buttonState, setButtonState] = useState<ButtonState>(
    ButtonState.Disabled
  );
  const previousButtonState = usePrevious(buttonState);
  const patientId = useSelector(
    (state: IState) =>
      state.checklist.documentsState[ChecklistStorageType.CHECKLIST]?.checklist
        ?.patientId
  );
  const checklistId = useSelector(
    (state: IState) =>
      state.checklist.documentsState[ChecklistStorageType.CHECKLIST]?.checklist
        ?.id
  );
  const [filterState, setFilterState] =
    useState<PatientCarePlanStatusFilterViewModel>(
      carePlanFilterState ?? PatientCarePlanStatusFilterViewModel.All
    );

  const [
    createOrUpdateCarePlan,
    {
      isLoading: isUpdatingCarePlan,
      isSuccess: isSuccessUpdatedCarePlan,
      data: carePlanData,
    },
  ] = useCreateOrUpdateCarePlanMutation();

  const [addChecklistProblems] = useAddChecklistProblemsMutation();
  const [updateChecklistProblem] = useUpdateChecklistProblemMutation();
  const [deleteChecklistProblem] = useDeleteChecklistProblemMutation();
  const [setUserPreference] = useSetPreferenceMutation();
  const [
    refreshCarePlan,
    {
      data: refreshCarePlanData,
      isSuccess: isSuccessCarePlanRefresh,
      isFetching: isRefreshingCarePlan,
    },
  ] = useLazyRefreshCarePlanQuery();

  const changeInCarePlan = (id: number, state: boolean) => {
    const newCarePlan = carePlanProblems!.map((x) =>
      Number(x.id) === id ? { ...x, inCarePlan: state } : x
    );
    if (updateCarePlanProblems) {
      updateCarePlanProblems(newCarePlan);
    }
  };

  const changeInActive = (id: number, state: boolean) => {
    const newCarePlans = carePlanProblems!.map((x) =>
      Number(x.id) === id ? { ...x, isActive: state } : x
    );
    const updatedCarePlan = newCarePlans.find((x) => Number(x.id) === id);
    updateChecklistProblem({
      problem: {
        checklistId: Number(checklistId),
        problemId: id,
        isActive: updatedCarePlan?.isActive ?? state,
      },
    });
    if (updateCarePlanProblems) {
      updateCarePlanProblems(newCarePlans);
    }
  };

  const deleteProblem = (id: number) => {
    const newCarePlans = carePlanProblems!.filter((x) => Number(x.id) !== id);
    deleteChecklistProblem({ checklistId: Number(checklistId), problemId: id });
    if (updateCarePlanProblems) {
      updateCarePlanProblems(newCarePlans);
    }
  };

  const onProblemsSelect = (selectedProblems: Problem[]) => {
    setShowAddDialog(false);
    const newProblems = [...carePlanProblems!];
    selectedProblems.forEach((item) => {
      newProblems.push({
        id: item.id,
        name: item.name,
        isActive: true,
        inCarePlan: false,
      } as unknown as ChecklistProblemViewModel);
    });
    addChecklistProblems({
      checklistId: Number(checklistId),
      problemIds: selectedProblems.map((x) => Number(x.id)),
    });
    if (updateCarePlanProblems) {
      updateCarePlanProblems(newProblems);
    }
  };

  const getValuesForHash = (
    problemsToHashValues: ChecklistProblemViewModel[]
  ) => {
    return problemsToHashValues
      .filter((x) => x.inCarePlan)
      .map((x) => Number(x.id) * 2 + (x.isActive ? 1 : 0));
  };

  useEffect(() => {
    if (!problems) {
      return;
    }
    const initialCount = problems.filter((x) => x.inCarePlan).length;
    const currentCount =
      carePlanProblems?.filter((x) => x.inCarePlan).length ?? 0;
    let newButtonState;
    if (initialCount === 0) {
      newButtonState =
        currentCount > 0 ? ButtonState.Create : ButtonState.Disabled;
    } else {
      newButtonState = ButtonState.Delete;
      if (currentCount > 0) {
        const initHash = hashNumbers(getValuesForHash(problems));
        const currentHash = hashNumbers(
          getValuesForHash(carePlanProblems ?? [])
        );
        newButtonState =
          initHash === currentHash ? ButtonState.Disabled : ButtonState.Update;
      }
    }
    if (newButtonState !== previousButtonState) {
      setButtonState(newButtonState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carePlanProblems, problems]);

  const onUpdateCarePlan = () => {
    const problemIds = [] as number[];
    const carePlanProblemIds = [] as number[];
    const patientProblemIds = [] as number[];
    const inactiveProblemIds = [] as number[];

    carePlanProblems!.forEach((item) => {
      problemIds.push(Number(item.id));
      if (item.inCarePlan) {
        carePlanProblemIds.push(Number(item.id));
      } else {
        patientProblemIds.push(Number(item.id));
      }
      if (!item.isActive) {
        inactiveProblemIds.push(Number(item.id));
      }
    });

    const request = {
      patientId: patientId,
      problemIds: problemIds,
      inactiveProblemIds: inactiveProblemIds,
      carePlanProblemIds: carePlanProblemIds,
      patientProblemIds: patientProblemIds,
      isDialog: false,
      checklistId: Number(checklistId ?? 0),
      updatePatientProblems: false,
    } as CreateOrUpdateCarePlanActionContextInput;

    createOrUpdateCarePlan({ model: request });
  };

  const problemsColumns = [
    {
      name: problemsColumnNames.name,
      title: 'Description',
    },
    {
      name: problemsColumnNames.inCarePlan,
      title: 'Enable Care Plan',
      getCellValue: (row: ChecklistProblemViewModel) => ({
        id: Number(row?.id ?? 0),
        inCarePlan: row.inCarePlan,
        isReadOnly: isReadOnly,
        changeInCarePlan,
      }),
    },
    {
      name: problemsColumnNames.isActive,
      title: 'Problem Status',
      getCellValue: (row: ChecklistProblemViewModel) => ({
        id: Number(row?.id ?? 0),
        isActive: row.isActive,
        isReadOnly: isReadOnly,
        changeInActive,
      }),
    },
    {
      name: problemsColumnNames.id,
      title: 'Actions',
      getCellValue: (row: ChecklistProblemViewModel) => ({
        id: Number(row?.id ?? 0),
        baseProblems: problems,
        isReadOnly: isReadOnly,
        deleteProblem,
      }),
    },
  ];

  useEffect(() => {
    if (isSuccessUpdatedCarePlan) {
      dispatch(
        updateCarePlanModelSuccess({
          checklist: carePlanData?.createOrUpdateCarePlan as ChecklistViewModel,
          problems: carePlanProblems as ChecklistProblemViewModel[],
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carePlanData]);

  useEffect(
    () => {
      if (isSuccessCarePlanRefresh) {
        dispatch(
          updateCarePlanModelSuccess({
            checklist:
              refreshCarePlanData?.refreshCarePlan as ChecklistViewModel,
            problems: carePlanProblems as ChecklistProblemViewModel[],
          })
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [refreshCarePlanData]
  );

  const mapCarePlanStatusFilter = (filterValue: string) => {
    switch (filterValue) {
      case PatientCarePlanStatusFilterViewModel.Active:
        return '0';
      case PatientCarePlanStatusFilterViewModel.Inactive:
        return '1';
      default:
        return '-1';
    }
  };

  const onChangeFilterState = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const value = event.target.value as string;

    setUserPreference({
      name: PATIENT_CARE_PLAN_FILTER_STATE,
      value: mapCarePlanStatusFilter(value),
    }).then(() =>
      refreshCarePlan({ patientId: patientId ?? 0, isDialog: false })
    );
    setFilterState(value as PatientCarePlanStatusFilterViewModel);
  };

  if (isUpdatingCarePlan || isRefreshingCarePlan) {
    return <Loader active={true} />;
  }

  return (
    <Grid container>
      <Grid item>
        <Box mb="32px">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            pb="16px"
          >
            <CarePlanProblemsGrid
              rows={carePlanProblems!}
              columns={problemsColumns}
              getRowId={(row) => Number(row.id)}
            />
            <Confirmation
              open={showDeleteConfirmation}
              title={'Delete Care Plan?'}
              okButtonText="Delete"
              okEvent={() => {
                setShowDeleteConfirmation(false);
                onUpdateCarePlan();
              }}
              cancelEvent={() => setShowDeleteConfirmation(false)}
            >
              <Typography>{`Are you sure you want to delete Care Plan?`}</Typography>
            </Confirmation>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            pb="16px"
          >
            {!isReadOnly ? (
              <>
                <Button
                  variant="text"
                  size="small"
                  color="primary"
                  startIcon={
                    <Icon
                      icon={ICONS.Add}
                      size={14}
                      color={COLORS.SYMPHONY_BLUE}
                    />
                  }
                  onClick={() => setShowAddDialog(true)}
                >
                  Add Problem
                </Button>
                {showAddDialog ? (
                  <ProblemsDialog
                    open={showAddDialog}
                    excludeIds={carePlanProblems!.map((x) => Number(x.id))}
                    onSelect={onProblemsSelect}
                    onClose={() => {
                      setShowAddDialog(false);
                    }}
                  />
                ) : null}
              </>
            ) : null}
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            pb="16px"
            id="care-plan"
          >
            <Typography variant="h4">{'Care Plan'}</Typography>
            <Box display="flex" alignItems="center" pb="16px">
              <Button
                variant="contained"
                size="small"
                color="primary"
                disabled={buttonState === ButtonState.Disabled}
                onClick={() => {
                  if (buttonState === ButtonState.Delete) {
                    setShowDeleteConfirmation(true);
                  } else {
                    onUpdateCarePlan();
                  }
                }}
              >
                {`${
                  buttonState === ButtonState.Disabled
                    ? 'Update'
                    : buttonState.toString()
                } Care Plan`}
              </Button>
              <StyledDivider orientation="vertical" />
              <Box display="flex" alignItems="center">
                <Typography variant="body1" style={{ marginRight: '10px' }}>
                  Show:
                </Typography>
                <FormControl disabled={isReadOnly}>
                  <Select
                    value={filterState}
                    onChange={onChangeFilterState}
                    disabled={isReadOnly}
                    size="small"
                    items={[
                      {
                        id: PatientCarePlanStatusFilterViewModel.Active,
                        name: 'Active',
                      },
                      {
                        id: PatientCarePlanStatusFilterViewModel.Inactive,
                        name: 'Inactive',
                      },
                      {
                        id: PatientCarePlanStatusFilterViewModel.All,
                        name: 'All',
                      },
                    ]}
                  />
                </FormControl>
              </Box>
            </Box>
          </Box>
          {!isUpdatingCarePlan && !isRefreshingCarePlan && hasCarePlan && (
            <Box>
              {isReadOnly ? (
                <ReviewSections
                  hideNumbers={true}
                  storageType={ChecklistStorageType.CAREPLAN}
                />
              ) : (
                <Sections
                  storageType={ChecklistStorageType.CAREPLAN}
                  autoSave
                  hideNumbers
                  disableSelection
                />
              )}
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default CarePlan;
