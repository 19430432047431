import { ChecklistViewModel } from 'graphql/graphqlTypes';
import { GetChecklistModelDocument } from 'graphql/hooks/getChecklist';
import { useCallback, useState } from 'react';
import axiosGitHubGraphQL from 'util/axiosHttp';

export const useGetCheckListAsync = () => {
  const [loading, setLoading] = useState(false);
  const [checklistData, setChecklistData] = useState<ChecklistViewModel[]>([]);
  const [error, setError] = useState<Error | null>(null);
  const getChecklistEndPoint = 'graphql?getChecklistModel';
  const getChecklists = useCallback(async (checklistIds: number[]) => {
    setLoading(true);
    setError(null);
    try {
      const checklistPromises = checklistIds.map((checklistId) =>
        axiosGitHubGraphQL.post(getChecklistEndPoint, {
          query: GetChecklistModelDocument,
          variables: { checklistId },
        })
      );

      const response = await Promise.all(checklistPromises);
      const checklists = response.map(
        (x) => x.data.data.getChecklist
      ) as ChecklistViewModel[];
      setChecklistData(checklists);
    } catch (err) {
      setError(err as Error);
    } finally {
      setLoading(false);
    }
  }, []);

  return { getChecklists, loading, checklistData, error };
};
