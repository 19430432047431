import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Divider } from '@mui/material';
import { CarePlanHeaderActions } from './CarePlanHeaderActions';
import {
  Grid,
  TableEditColumn,
  TableEditRow,
  TableRowDetail,
} from '@devexpress/dx-react-grid-material-ui';
import {
  ChangeSet,
  EditingState,
  IntegratedSorting,
  RowDetailState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  GridEditActions,
  StubCellComponent,
  SymphonyTable,
  SymphonyTableHeaderRow,
  TableEditCell,
  TableEditRows,
  useData,
} from 'components/gridFormatters';
import { CarePlanLabelCell } from './CarePlanLabelCell';
import { ToggleCellComponent } from './ToggleCellComponent';
import { CarePlanDetails } from './CarePlanDetails';
import { carePlanColumnExtensions, carePlanColumns } from './consts';
import Loader from 'components/loader';
import { Getter } from '@devexpress/dx-react-core';
import { CarePlanFilter, CarePlanStatusActive } from './CarePlanFilter';
import { CarePlanEditCell } from './CarePlanEditCell';
import { IState } from 'store';
import { rearrangeSecondColumnsEditLast } from 'components/home/attachmentsList/attachments.helpers';
import {
  commitProblems,
  storeCarePlan,
} from 'store/patientdetails/patientDetails.carePlan.slice';
import { useDispatch } from 'react-redux';
import { ILookupValue } from 'backend/types/lookupValue';
import {
  GetCarePlanQuery,
  useLazyGetCarePlanQuery,
} from 'graphql/hooks/getCarePlan';
import { useUpdateChecklistProblemMutation } from 'graphql/hooks/updateChecklistProblem';
import {
  ChecklistProblemViewModel,
  ChecklistViewModel,
  PatientCarePlanStatusFilterViewModel,
  PatientCarePlanViewModel,
} from 'graphql/graphqlTypes';
import { setIsInEditMode } from 'store/patientdetails/patientDetailsSlice';

export interface ICarePlanTabProps {
  episodeId: number;
  patientId: number;
}

const onProblemStatus =
  (filter: ILookupValue) => (problem: ChecklistProblemViewModel) => {
    switch (filter.name) {
      case PatientCarePlanStatusFilterViewModel.Active:
        return problem.isActive;
      case PatientCarePlanStatusFilterViewModel.Inactive:
        return !problem.isActive;
      case PatientCarePlanStatusFilterViewModel.All:
        return true;
      default:
        return true;
    }
  };

const CarePlan = ({ episodeId, patientId }: ICarePlanTabProps) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [editEnabled, setEditEnabled] = useState(true);
  const [editingRowIds, setEditingRowIds] = useState<string[]>([]);
  const isInEditMode = editingRowIds.length > 0;
  const [expandedRowIds, setExpandedRowIds] = useState<string[]>([]);
  const [problemFilter, setProblemFilter] = useState(CarePlanStatusActive);
  const [updateChecklistProblem] = useUpdateChecklistProblemMutation();

  useEffect(() => {
    if (isInEditMode) {
      dispatch(setIsInEditMode({ isInEditMode: isInEditMode }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInEditMode]);

  const { data: carePlanData, isFetching } = useData(
    (state: IState) => state.patientDetailsCarePlan.episodeTab[episodeId],
    useLazyGetCarePlanQuery,
    {
      request: {
        episodeId: episodeId.toString(),
        patientId: patientId.toString(),
      },
    },
    (data: GetCarePlanQuery) =>
      storeCarePlan({
        episodeId,
        data: data.getCarePlan as PatientCarePlanViewModel,
      }),
    !patientId || !episodeId
  );

  const collapseAll = () => setExpandedRowIds([]);
  const expandAll = () =>
    setExpandedRowIds(carePlanData?.problems?.map(({ id }) => id) ?? []);

  const commitChanges = (changeSet: ChangeSet) => {
    const { changed } = changeSet;
    if (changed) {
      carePlanData?.problems.forEach((x) => {
        if (changed[x.id]) {
          const updatedRow = {
            ...x,
            ...changed[x.id],
          } as ChecklistProblemViewModel;
          updateChecklistProblem({
            problem: {
              checklistId: checklistId,
              problemId: Number(updatedRow.id),
              isActive: updatedRow.isActive,
              note: updatedRow.note,
            },
          });
        }
      });
    }
    dispatch(commitProblems({ episodeId, changeSet }));
    dispatch(setIsInEditMode({ isInEditMode: false }));
  };

  const lastUpdatedBy =
    carePlanData?.checklist.updatedBy?.fullName === null
      ? carePlanData?.checklist.author?.fullName
      : carePlanData?.checklist.updatedBy?.fullName;

  const rows =
    carePlanData?.problems?.filter(onProblemStatus(problemFilter)) ?? [];
  const checklistId = Number(carePlanData?.checklist.id ?? 0);
  const disableActions = rows.length === 0 || !checklistId;

  return (
    <Box>
      <Loader active={isFetching || loading} />
      <CarePlanHeaderActions
        episodeId={episodeId}
        patientId={patientId}
        checklistId={checklistId}
        lastUpdateBy={lastUpdatedBy}
        updatedOn={
          carePlanData?.checklist.updatedOn ??
          carePlanData?.checklist.finishedOn
        }
        setLoading={setLoading}
        disableActions={disableActions}
      />
      <Divider style={{ margin: '30px 0' }} />
      <CarePlanFilter
        expandAll={expandAll}
        collapseAll={collapseAll}
        setProblemFilter={setProblemFilter}
        problemFilter={problemFilter}
        disableActions={disableActions}
      />

      <Grid columns={carePlanColumns} rows={rows} getRowId={(row) => row.id}>
        <SortingState columnExtensions={carePlanColumnExtensions} />
        <EditingState
          onCommitChanges={commitChanges}
          columnExtensions={carePlanColumnExtensions ?? []}
          editingRowIds={editingRowIds}
          onEditingRowIdsChange={(curEditingRowIds: Array<number | string>) => {
            setEditingRowIds(curEditingRowIds as string[]);
            setEditEnabled(curEditingRowIds.length === 0);
          }}
        />
        <IntegratedSorting columnExtensions={carePlanColumnExtensions} />
        <RowDetailState
          expandedRowIds={expandedRowIds}
          onExpandedRowIdsChange={(ids) => setExpandedRowIds(ids as string[])}
        />
        <SymphonyTable
          cellComponent={CarePlanLabelCell}
          columnExtensions={carePlanColumnExtensions}
        />
        <TableEditRow
          cellComponent={CarePlanEditCell}
          rowComponent={TableEditRows}
        />
        <TableEditColumn
          showEditCommand
          cellComponent={(props) => (
            <TableEditCell
              {...props}
              style={{ flexDirection: 'row-reverse', paddingRight: '12px' }}
            />
          )}
          headerCellComponent={StubCellComponent}
          commandComponent={(props) => (
            <GridEditActions
              {...props}
              editEnabled={editEnabled}
              divider={false}
              itemName={'care plan'}
              onCancel={() =>
                dispatch(setIsInEditMode({ isInEditMode: false }))
              }
            />
          )}
          width="180px"
        />
        <SymphonyTableHeaderRow showSortingControls />
        <TableRowDetail
          contentComponent={(props) => (
            <CarePlanDetails
              {...props}
              checklist={carePlanData?.checklist as ChecklistViewModel}
            />
          )}
          toggleCellComponent={ToggleCellComponent}
        />
        <Getter name="tableColumns" computed={rearrangeSecondColumnsEditLast} />
      </Grid>
    </Box>
  );
};

export default CarePlan;
