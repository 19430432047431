import React, { ChangeEvent, useEffect } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import ServerAutocomplete from 'components/autocomplete/ServerAutocomplete';
import { suggestEntities } from 'store/patientdetails/middlewares';
import { useDispatch, useSelector } from 'react-redux';
import { IEditorProps } from 'backend/types/grid/IEditorProps';
import { ILookupValue } from 'backend/types/lookupValue';
import { COLORS } from 'consts/styles';
import { IconFallBack } from 'components/IconFallBack';
import { Box, MenuProps, Select, Typography } from '@mui/material';
import styled from 'styled-components';
import { CustomTooltip } from 'components/tooltip/CustomTooltip';
import { Ellipsis } from 'components/text';
import { IState } from 'store';

const StyledSelect = styled(Select)({
  background: COLORS.WHITE,
  overflow: 'hidden',
  fontSize: '14px',
  maxWidth: '100%',
  width: '100%',
  marginRight: '8px',
});

const LookupFilter = (props: IEditorProps) => {
  const [multiSelect, changeMultiSelect] = React.useState<string[]>([]);
  const dispatch = useDispatch();
  const handleAutoCompleteChange = (
    _event: ChangeEvent<unknown>,
    newValue: ILookupValue
  ) => props.onChange(newValue ? String(newValue.id) : '');

  const handleMultiSelectClose = () => {
    props.onChange(multiSelect.join(','));
  };

  const multiValues = useSelector(
    (state: IState) => state.home?.homeModel?.filterBy
  );
  useEffect(() => {
    if (
      multiValues &&
      (props.column.name === 'Patient_Tags' || props.column.multiValues)
    ) {
      const valuesToSyncFromRedux = multiValues?.find(
        (tagFilter) => tagFilter.columnName === props.column.name
      );
      changeMultiSelect(
        valuesToSyncFromRedux && valuesToSyncFromRedux.value
          ? valuesToSyncFromRedux.value.split(',')
          : []
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multiValues]);

  const maximumSelectedValuesToShowInInputBox = 5;

  const renderMultiValue = (isTagsSelect: boolean, selected: unknown) => {
    return (
      <div style={{ display: isTagsSelect ? 'flex' : 'inline' }}>
        {multiSelect.length < maximumSelectedValuesToShowInInputBox ? (
          props.column?.filterValues
            .filter((x) => (selected as string[]).indexOf(String(x.id)) > -1)
            .map((item) =>
              isTagsSelect ? (
                <IconFallBack
                  key={item.id}
                  name={item.name}
                  image={String(item.image)}
                  type="Tag"
                  iconSize={20}
                />
              ) : (
                item.name
              )
            )
        ) : (
          <CustomTooltip
            title={
              <div>
                {props.column?.filterValues
                  .filter(
                    (x) => (selected as string[]).indexOf(String(x.id)) > -1
                  )
                  .map((item) => (
                    <Ellipsis key={item.id}>
                      <Typography noWrap variant="body1">
                        {item.name}
                      </Typography>
                    </Ellipsis>
                  ))}
              </div>
            }
          >
            <Typography>{`${multiSelect.length} selected`}</Typography>
          </CustomTooltip>
        )}
      </div>
    );
  };

  const menuProps = {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'right',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'right',
    },
    style: {
      left: '0',
      marginTop: '2px',
      maxHeight: 350,
      width: 250,
    },
  } as MenuProps;

  switch (props.column?.name) {
    case 'Patient_Tags':
      return (
        <StyledSelect
          multiple
          variant="outlined"
          autoWidth={true}
          data-testid="tags-filter"
          data-cy="home-grid-tags-filter"
          onClose={handleMultiSelectClose}
          onChange={(event) =>
            changeMultiSelect(event.target.value as string[])
          }
          style={{
            height: '32px',
          }}
          MenuProps={menuProps}
          renderValue={(selected) => renderMultiValue(true, selected)}
          value={multiSelect}
        >
          {props.column.filterValues?.map((item) => (
            <MenuItem key={item.id} value={String(item.id)}>
              <Box display="flex" alignItems="center">
                <Checkbox
                  color="primary"
                  checked={multiSelect.indexOf(String(item.id)) > -1}
                />
                <IconFallBack
                  name={item.name}
                  image={String(item.image)}
                  type="Tag"
                />
                <Typography style={{ marginLeft: '6px' }}>
                  {item.name}
                </Typography>
              </Box>
            </MenuItem>
          ))}
        </StyledSelect>
      );
    case 'Episode_RequestingClinician':
    case 'Episode_ServicingClinician':
    case 'Episode_AdmittingClinician':
    case 'Episode_Facility':
      return (
        <ServerAutocomplete
          id={props.column.name}
          label={props.column.title}
          data-testid="autocomplete-filter"
          suggestItems={(
            request: string,
            callback: (arg: ILookupValue[]) => void
          ) => dispatch(suggestEntities('Provider', request, callback))}
          onChange={handleAutoCompleteChange}
        />
      );
    default:
      return props.column.multiValues ? (
        <StyledSelect
          variant="outlined"
          multiple
          autoWidth={true}
          style={{
            height: '32px',
          }}
          data-testid="multi-value-filter"
          onClose={handleMultiSelectClose}
          onChange={(event) =>
            changeMultiSelect(event.target.value as string[])
          }
          renderValue={(selected) => renderMultiValue(false, selected)}
          value={multiSelect}
          MenuProps={menuProps}
        >
          <MenuItem value="0">
            <em>Empty</em>
          </MenuItem>
          {props.column.filterValues?.map((item) => (
            <MenuItem key={item.id} value={String(item.id)}>
              {item.image ? <img alt="" src={String(item.image)} /> : null}
              <ListItemText style={{ marginLeft: '9px' }} primary={item.name} />
            </MenuItem>
          ))}
        </StyledSelect>
      ) : (
        <StyledSelect
          variant="outlined"
          placeholder={props.column?.title}
          onChange={(event) => {
            return props.onChange(String(event.target.value));
          }}
          data-testid="select-filter"
          MenuProps={menuProps}
          value={props.value ?? ''}
        >
          <MenuItem value="0">
            <em>Empty</em>
          </MenuItem>
          {props.column.filterValues?.map((item) => (
            <MenuItem key={item.id} value={String(item.id)}>
              {item.name}
            </MenuItem>
          ))}
        </StyledSelect>
      );
  }
};

export default LookupFilter;
