import React from 'react';
import { Typography } from '@mui/material';
import { COLORS } from 'consts/styles';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { IState } from 'store';

interface IStyledEpisodeNumberWrapperProps {
  episodeLabelHasBorder: boolean;
  isPrintingOpen: boolean;
}

const getPadding = (props: IStyledEpisodeNumberWrapperProps): string => {
  if (props.isPrintingOpen) {
    return '2px 24px';
  } else {
    return !props.episodeLabelHasBorder ? '16px 28px' : '16px 0 0 0';
  }
};

const StyledEpisodeNumberWrapper = styled.div<IStyledEpisodeNumberWrapperProps>`
  padding: ${(props) => getPadding(props)};
  margin: ${(props) =>
    props.episodeLabelHasBorder && !props.isPrintingOpen ? '0px 28px' : 0};
  border-top: ${(props) =>
    props.episodeLabelHasBorder && !props.isPrintingOpen
      ? `1px dashed ${COLORS.GREY25}`
      : 0};
`;

export interface IPatientCardProps {
  episodeNumber?: string | null;
  episodeLabelHasBorder?: boolean;
}

const StyledTypography = styled(Typography)`
  display: inline;
  margin-right: 4px;
`;

const EpisodeId = (props: IPatientCardProps) => {
  const { episodeLabelHasBorder, episodeNumber } = props;
  const printDocuments = useSelector(
    (state: IState) => state.ui.print.documents
  );
  const isPrintOpen = printDocuments.isPrintOpen;
  return (
    <>
      {episodeNumber && (
        <StyledEpisodeNumberWrapper
          episodeLabelHasBorder={episodeLabelHasBorder ?? false}
          isPrintingOpen={isPrintOpen}
        >
          <StyledTypography variant="body2">Episode ID:</StyledTypography>
          <Typography display="inline" variant="body1">
            {episodeNumber}
          </Typography>
        </StyledEpisodeNumberWrapper>
      )}
    </>
  );
};

export default EpisodeId;
