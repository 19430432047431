import React, { forwardRef, useEffect } from 'react';
import { Grid } from '@mui/material';
import store, { IState } from 'store';
import { ChecklistStorageType } from 'store/actions/types';
import { COLORS } from 'consts/styles';
import styled from 'styled-components';
import { PatientCardWrapper } from 'pages/workflow/PatientCardWrapper';
import ReviewSections from 'components/review/sections';
import {
  api,
  useGetPatientCardInfoQuery,
} from 'graphql/hooks/getPatientCardInfo';

import { useGetCheckListAsync } from 'hooks/useGetCheckListAsync';
import { PRINT_SOURCES } from 'backend/types/printSources';
import { PrintPreviewWrapper } from 'styles/printStyles';
import { useSelector } from 'react-redux';
import Loader from 'components/loader';

const StyledContent = styled.div<{ $isHidden?: boolean }>`
  justify-content: space-between;
  background-color: ${COLORS.GREY4};
  padding-top: 5px;

  ${({ $isHidden }) =>
    !$isHidden &&
    `@media screen {
        display: none;
      }
    `}
`;

const StyledContainer = styled.div`
  padding: 0 30px;
  min-height: 40vh;
`;

interface IMozartPrintLayoutProps {
  isPrintOpen: boolean;
  storageType: ChecklistStorageType;
  printSource: PRINT_SOURCES;
}

const ChecklistPrintLayout = forwardRef<
  HTMLDivElement,
  IMozartPrintLayoutProps
>(({ isPrintOpen, storageType, printSource }, ref) => {
  const patientId = useSelector(
    (state: IState) => state.patientDetails.patientId
  );
  const selectedChecklistIds = useSelector(
    (state: IState) => state.ui.print.documents.nonMozart.selectedChecklistIds
  );

  const { getChecklists, loading, checklistData, error } =
    useGetCheckListAsync();
  const { data: patientInfo } = api.endpoints.getPatientCardInfo.select({
    patientId: patientId ?? 0,
  })(store.getState());
  const { data: patientData, isFetching: isPatientFetching } =
    useGetPatientCardInfoQuery(
      {
        patientId: patientId ?? 0,
      },
      {
        skip: !patientId && !patientInfo,
      }
    );

  const hideMediaStyle = printSource === PRINT_SOURCES.DOCUMENT;
  useEffect(() => {
    const checklistIds = selectedChecklistIds ?? [];
    getChecklists(checklistIds);
  }, [getChecklists, selectedChecklistIds]);

  return (
    <div ref={ref}>
      {isPrintOpen && (
        <PrintPreviewWrapper>
          <StyledContent $isHidden={hideMediaStyle}>
            <StyledContainer>
              <Grid container spacing={0} style={{ position: 'relative' }}>
                <Grid item xs={12}>
                  <PatientCardWrapper
                    patientData={patientInfo ?? patientData}
                    storageType={storageType}
                  />
                </Grid>
                <Grid item xs={12}>
                  {!error &&
                    !loading &&
                    checklistData.map((checklistItem) => (
                      <ReviewSections
                        key={checklistItem.id}
                        checklist={checklistItem}
                        storageType={storageType}
                      />
                    ))}
                </Grid>
              </Grid>
            </StyledContainer>
          </StyledContent>
          <Loader active={loading || isPatientFetching} />
        </PrintPreviewWrapper>
      )}
    </div>
  );
});
ChecklistPrintLayout.displayName = 'ChecklistPrintLayout';

export default ChecklistPrintLayout;
