import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ChecklistStorageType } from 'store/actions/types';

export interface IDocumentsState {
  isPrintOpen: boolean;
  episodeId: number;
  storageType: ChecklistStorageType;
}

export interface IMozartDocumentsState {
  checklistIds?: number[];
  mozartInstanceId?: string;
  viewOnly: boolean;
}

export interface INonMozartDocumentsState {
  selectedChecklistIds?: number[];
}

export interface DocumentState extends IDocumentsState {
  mozart: IMozartDocumentsState;
  nonMozart: INonMozartDocumentsState;
}

export const initialDocumentState: DocumentState = {
  isPrintOpen: false,
  episodeId: 0,
  storageType: ChecklistStorageType.MOZART,
  mozart: {
    checklistIds: [],
    mozartInstanceId: '',
    viewOnly: true,
  },
  nonMozart: {
    selectedChecklistIds: [],
  },
};
interface IDocumentStateArgs {
  episodeId?: number;
  isPrintOpen?: boolean;
  storageType?: ChecklistStorageType;
}
interface IMozartDocumentStateArgs extends IDocumentStateArgs {
  checklistIds?: number[];
  mozartInstanceId?: string;
  viewOnly?: boolean;
}

interface INonMozartDocumentStateArgs extends IDocumentStateArgs {
  selectedChecklistIds?: number[];
}

export interface DocumentStateClearArgs {
  persistEditable?: boolean;
}

export const documentSlice = createSlice({
  name: 'documentsState',
  initialState: initialDocumentState,
  reducers: {
    updateMozartDocumentState: (
      state,
      {
        payload: {
          episodeId,
          checklistIds,
          mozartInstanceId,
          viewOnly,
          storageType,
        },
      }: PayloadAction<IMozartDocumentStateArgs>
    ) => {
      state.isPrintOpen = true;
      state.storageType = storageType ?? ChecklistStorageType.MOZART;
      state.episodeId = episodeId ?? 0;
      state.mozart.checklistIds = checklistIds;
      state.mozart.mozartInstanceId = mozartInstanceId;
      state.mozart.viewOnly = viewOnly ?? true;
    },
    updateNonMozartDocumentState: (
      state,
      {
        payload: { episodeId, selectedChecklistIds, storageType },
      }: PayloadAction<INonMozartDocumentStateArgs>
    ) => {
      state.isPrintOpen = true;
      state.episodeId = episodeId ?? 0;
      state.storageType = storageType ?? ChecklistStorageType.CHECKLIST;
      state.nonMozart.selectedChecklistIds = selectedChecklistIds;
    },
    clearDocumentState: () => {
      return {
        ...initialDocumentState,
      };
    },
  },
});

export const {
  updateMozartDocumentState,
  updateNonMozartDocumentState,
  clearDocumentState,
} = documentSlice.actions;
export default documentSlice.reducer;
